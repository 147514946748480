import { BrowserRouter, Route,Routes } from "react-router-dom";

import { Home,Services,Contact,Affiliate,Tours,Travel } from './pages/pages'
import Home2 from "./Home2";

const App=()=> {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Home/>} />
          <Route path="/services" element={<Services/>} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services/tours" element={<Tours/>} />
          <Route path="/services/travel" element={<Travel/>} />
          <Route path="afiliate" element={<Affiliate />} /> */}
          <Route path="/" element={<Home2 />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
