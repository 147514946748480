import Card from '../Card/Card'
import './service.css'

const Service = ({compName,compDesc,compCost,ImageLink,ImgAlt,adImg,adsTopTitle}) => {
  return (
    <div className='app__service-contain'>
      <div className='app__service-card-container'>
        <Card name={compName} />
      </div>  
      <div className='app__service-desc'>
        <h3 className="app__service-desc-head">{compCost}</h3>
        <span className='app__service-desc-text'>{compDesc}</span>
      </div>
    </div>
  )
}

export default Service