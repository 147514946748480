import { Card } from '../../components/components'
import Show from '../../components/heroShow/Show'
import './home.css'

const heroText = [
  {
    "id":"1",
    "title":"Travel",
    "sub":"And tell a story",
    "paragraph": "Blackman safaris gives an immense interaction with nature and wildlife. We make sure that you get an exemptional experience which leaves you with, nothing but, stories to tell."
  },
  // {
  //   "id":"2",
  //   "title":"",
  //   "sub":"",
  //   "paragraph": "zd"
  // },
  // {
  //   "id":"3",
  //   "title":"",
  //   "sub":"",
  //   "paragraph": ""
  // },
]


export default function Home() {
  return (
    <div id='hero' className='hero' >
      <div className='heroContent'>
        <div className='txt'>
          {
            heroText.map((txt,index)=>{
              return(
                <>
                {/* <div className='realHero'> */}
                  <h1 className='heroTitle'>{txt.title}</h1>
                  <sub className='heroSub'>{txt.sub}</sub>
                {/* </div> */}
                </>
              )
            })
          }
        </div>
        <div className='hero-pictoral'>
          <Show />
        </div>
      </div>
    </div>
  )
}
