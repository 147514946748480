import './card.css'

const Card=({name})=> {

  return (
    <div className='app__card'>
        <h1>{name}</h1>
     <hr className="app__card-bottom"/>
    </div>
  )
}

export default Card