import { MdEmail, MdLocationPin } from 'react-icons/md'
import { BsWhatsapp,BsTelephone } from 'react-icons/bs'

import './contact.css'

const contactD = [
  {
    "id": "1",
    "tel": "+256-703-830-867",
    "watsapp": "+256-776-532-225",
    "email":"talesofblackman@gmail.com",
    "address": "P.O Box 200659 Nakawa, Kampala-Uganda"
  },
]

const Contact=()=> {
  return (
    <div id='contact' className='contact'>
      <h5>LET'S PLAN YOUR NEXT JOURNEY</h5>
      <div className='contactBody'>
        <span className='contactHead'>On your marks ?</span>
        <p>We believe every journey becomes a story worth telling. We tailor unique adventures to 
        create unforgettable memories for you. Experience the thrill of discovery, personal growth, 
        and lasting joy. <b className='getSet'>Get Set</b>, write your story.
        </p>
        {
          contactD.map((detail,index)=>{
            return(
              <ul className='cItems'>
                <li key={index.id}><BsTelephone style={{marginRight:10, fontSize:17}} />{detail.tel}</li>
                <li key={index.id}><BsWhatsapp style={{marginRight:10, fontSize:18}} />{detail.watsapp}</li>
                <li key={index.id}><MdEmail style={{marginRight:10, fontSize:20}} />{detail.email}</li>
                <li key={index.id}><MdLocationPin style={{marginRight:10, fontSize:20}} />{detail.address}</li>
              </ul>
            )
          })
        }
      </div>
      {/* <div className='contact-home'>
        <h1>Contact Us</h1>
        <div className='app__contact-details-contain'>
          <div className='app__contact-detail'>
            <div id='app__contact-span'>
              <span><BsTelephone color='#132733' size={30} /></span>
              <span><MdEmail color='#132733' size={30} /></span>
              <span><MdLocationPin color='#132733' size={38} /></span>
              <span><BsWhatsapp color='#132733' size={30} /></span>
            </div>
            <div className='app__contact-detail-text'>
              <p>+256-703-830-867</p>
              <p>talesofblackman@gmail.com</p>
              <p>P.O Box 200659 Nakawa, Kampala-Uganda</p>
              <p>+256-776-532-225</p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default Contact