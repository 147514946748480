import { Navigation, Pagination, A11y,Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'
import 'swiper/css/pagination'

import { Service } from '../../../components/components'

import './ToursAndTravel.css'

const travels = [
  {
    "id":1,
    "item":"Air ticket booking",
    "description": "Our efficient air ticket booking service ensures smooth travel. ",
    "cost": "Fly always, stress free.",
  },
  {
    "id":2,
    "item":"Visa processing",
    "description": "Our visa processing assistance streamlines the application process, making international travel a breeze.",
    "cost": "Leave the paperwork us.",
  },
  {
    "id":3,
    "item":"Airport transfers",
    "description": "Our airport transfer service ensures a comfortable and punctual journey from touchdown to your destination.",
    "cost": "Have the perfect start.",
  },
]

const Travel = () => {
  return (
    <div className='app__services_tours-page'>
      <Swiper
      breakpoints={{
        320: { width:320, slidesPerView: 1, },
        376: { width:376, slidesPerView: 1, }
      }}
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={5}
      slidesPerView={3}
      navigation
      pagination={{clickable: true}}
      autoplay={{ delay: 6000 }}
      scrollbar={{clickable: true}}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
      >
        {
          (travels.map(travel=>{
              return(
              <SwiperSlide key={travel.id} id={travel.id}>
                <div className='tour travl'>
                  <Service compName={travel.item} compDesc={travel.description} compCost={travel.cost}  />
                </div>
              </SwiperSlide>
              )
            }))
        }
      </Swiper>
    </div>
  )
}

export default Travel