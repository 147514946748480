import Matatu from '../../../components/Matatu/Matatu.component'

const destinations = [
  {
    "id":"1",
    "title": "Natural Wonders",
    "sub1":"Rwenzori Mts",
    "sub2":"Mount Elgon",
    "pic1":"",
    "pic2":"",
    "picURL1":"",
    "picURL2":"",
    "wordings1":"Also called 'Mountains of the Moon', these snow-capped peaks offer stunning scenery and challenging trekking routes.",
    "wordings2":"An extinct volcano with the world's largest caldera, providing beautiful hikes and rich biodiversity.",
    "picALT1":"",
    "picALT2":"",

  },
  {
    "id":"2",
    "title": "Wildlife & Safari Experience",
    "sub1":"Bwindi Impenetratble N.P",
    "sub2":"Queen Elizabeth N.P",
    "sub3":"Murchsion Falls N.P",
    "pic":"",
    "picURL":"",
    "wordings1":"Home to half of the world's remaining mountain gorillas, offering an unforgetable gorilla trekking experience.",
    "wordings2":"Known for it's tree-climbing lions, diverse wildlife, and scenic Kazinga Channel.",
    "wordings3":"Uganda's largest national national park, offering game drives, boat cruises, and the iconic Murchsion Falls",
    "picALT":"",
  },
  {
    "id":"3",
    "title": "Cultural and Historical Sites",
    "sub1":"Kasubi Tombs",
    "sub2":"Ndere Cultural Centre",
    "sub3":"Namugongo Martyrs Shrine",
    "sub4":"Fort Portal",
    "pic":"",
    "picURL":"",
    "wordings1":"A UNESCO World Heritage site, these are the burial grounds for Buganda kings, showcasing traditional architecture and history.",
    "wordings2":"A vibrant showcase of Uganda’s diverse cultural heritage through music, dance, and drama.",
    "wordings3":"A significant religious site commemorating the Ugandan martyrs.",
    "wordings4":"A town rich in history with numerous colonial-era buildings and the nearby Amabere Caves.",
    "picALT":"",
  },
  {
    "id":"4",
    "title": "Unique Experiences",
    "sub1":"Batwa Cultural Experience",
    "sub2":"Homestays and Community Tours",
    "pic":"",
    "picURL":"",
    "wordings1":"Engage with the Batwa pygmies, learning about their traditional lifestyle and rich cultural heritage.",
    "wordings2":"Experience authentic Ugandan life, participate in traditional activities, and contribute to local communities.",
    "picALT":"",
  },
]

export default function Destinations() {
  return (
    <div>
      {
        
        destinations.map((destination,index)=>{
          return(
            <div key={index.id}>
              <Matatu title={destination.title} /*subtitle={destination.sub3} matatuu={destination.wordings3}*/ subtitle1={destination.sub1} matatuu1={destination.wordings1}
              subtitle2={destination.sub2} matatuu2={destination.wordings2} subtitle3={destination.sub4} matatuu3={destination.wordings4}
               />
            </div>
          )
        })
      }
    </div>
  )
}
