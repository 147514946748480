import Navbar from './components/Navbar/Navbar'
import { Footer } from './components/components'
import Home from './pages/Home/Home'
import { Contact, Services } from './pages/pages'

export default function Home2() {
  return (
    <div>
      <Navbar />
      <Home />
      <Services />
      <Contact />
      <Footer />
    </div>
  )
}
