import './matatu.styles.css'

export default function Matatu({title,subtitle,source,altIMG,matatuu,subtitle1,source1,altIMG1,matatuu1
    ,subtitle2,source2,altIMG2,matatuu2,subtitle3,source3,altIMG3,matatuu3,subtitle4,source4,altIMG4,matatuu4,
}) {
  return (
    <div className='matatu'>
        <h1>{title}</h1>
        <div className='matatu-body'>
            <div className='matatu-content'>
                <h2>{subtitle}</h2>
                <p>            
                    <div className='imgs'>
                        <img src={source} alt={altIMG} />
                    </div>
                    {matatuu}
                </p>
            </div>
            <div className='matatu-content'>
                <h2>{subtitle1}</h2>
                <p>            
                    <div className='imgs'>
                        <img src={source1} alt={altIMG1} />
                    </div>
                    {matatuu1}
                </p>
            </div>
            <div className='matatu-content'>
                <h2>{subtitle2}</h2>
                <p>            
                    <div className='imgs'>
                        <img src={source2} alt={altIMG2} />
                    </div>
                    {matatuu2}
                </p>
            </div>
            <div className='matatu-content'>
                <h2>{subtitle3}</h2>
                <p>            
                    <div className='imgs'>
                        <img src={source3} alt={altIMG3} />
                    </div>
                    {matatuu3}
                </p>
            </div>
            <div className='matatu-content'>
            <h2>{subtitle4}</h2>
            <p>            
                <div className='imgs'>
                    <img src={source4} alt={altIMG4} />
                </div>
                {matatuu4}
            </p>
        </div>
        </div>
        
    </div>
  )
}
