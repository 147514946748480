import {useState} from 'react'
import {GiHamburgerMenu} from 'react-icons/gi'
import {ImCross} from 'react-icons/im'

import './navbar.css'
import Logo from '../Logo/Logo'

const menus = [{"id":"1","item":"Home","url":"#hero"},{"id":"2","item":"Services","url":"#services"},{"id":"3","item":"Contact","url":"#contact"}]

const Navbar=()=> {
  const [toggle, setToggle] = useState(false)

  const toggleHandler=()=>{
    setToggle(!toggle)
  }

  return (
    <nav>
    <a href='/' className='navLogo'><Logo /><span className='logo-txt'>Blackman safaris</span></a>
    <div className='menu'>
      {
        menus.map((menu,index)=>{
          return(
            <a className="menu-item" key={index.id} href={menu.url}>{menu.item}</a>
          )
        })
      }
    </div>
    <div className='sm-menu'>
      <span className='hamburger'>
        {
          !toggle ? <GiHamburgerMenu size={25} color='#fff' onClick={toggleHandler} /> : <ImCross size={17} color='#fff' onClick={toggleHandler} />
        }
      </span>
      <div className='burgers'>
        {
          toggle === false ? null: (
            menus.map((menu,index)=>{
            return(
              <a className="burger" key={index.id} href={menu.url}>{menu.item}</a>
            )
          })
          )
        }
      </div>
    </div>
        {/* <div className='app__nav'>
          <a className="logo-container" href="/"><img src={logo} alt='Logo'/></a>
          <div className='nav-link__contain'>
            <a className="nav-link" href="/">HOME</a>
            <a href="services" className="nav-link">SERVICES</a>
            <a className="nav-link" href="contact">CONTACT</a>
          </div>
        </div>
        <div className="app__menu-nav">
          <div className='app__menu-nav-top'>
            <div className="logo"><a href="/"><img src={logo} alt='Logo'/></a></div>
            <div className='app__nav-toggle' >
            {
              !toggle ? <GiHamburgerMenu size={17} color='#fff' onClick={toggleHandler} /> : <ImCross size={17} color='#fff' onClick={toggleHandler} />
            }
            </div>
          </div>
          
          {
            toggle === false ? null: (
              <div className="app__nav-menu">
                <a className="nav-link" href="/">HOME</a>
                <a href="services" className="nav-link">SERVICES</a>
                <a className="nav-link" href="contact">CONTACT</a>
              </div>
            )
          }
          
        </div> */}
    </nav>
  )
}

export default Navbar