import { Navbar,Product,Footer } from '../../components/components'

import { useEffect } from 'react';

import { Navigation, Pagination, A11y,Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'
import 'swiper/css/pagination'

import './afiliate.css'


const products=[
  {
    id: "1",
    url: require('../../assets/IMG-20230817-WA0062.webp'),
    Alt: "Male branded shirt"
  },
  {
    id: "2",
    url: require('../../assets/IMG-20230817-WA0058.webp'),
    Alt: "Female branded shirt."
  },
  {
    id: "3",
    url: require('../../assets/IMG-20230826-WA0002.webp'),
    Alt: "Tents"
  },
  {
    id: "4",
    url: require('../../assets/IMG-20230927-WA0025.webp'),
    Alt: "Kampala branded cap"
  },
  {
    id: "5",
    url: require('../../assets/IMG-20230927-WA0024.webp'),
    Alt: "UG branded cape"
  },
]

  const storeProd = JSON.stringify(products)
  localStorage.setItem("products",storeProd)

  const storedProd = localStorage.getItem("products")
  export const retrievedProd = JSON.parse(storedProd)

// export function storeProducts(){
//   // Convert the image array to a JSON string
//   const productsJSON = JSON.stringify(products)

//   // Save the JSON string to localStorage
//   localStorage.setItem("products",productsJSON)
// }

// export function retrieveProducts(){
//   // Retrieve the JSON string from localStorage
//   const storedProductsJSON = localStorage.getItem("products")

//   // Parse the JSON string to get the array back
//   const storedProducts = JSON.parse(storedProductsJSON)
// }

const Afiliate=()=> {
  // useEffect(()=>{
  //   storeProducts()
  // },[])
  return (
    <div className='app__afiliate'>
        <div className='app__afiliate-nav'>
            <Navbar/>
        </div>
        <div className='app__afiliate-contain'>
        <h1>On sale</h1>
        <div className='app__afiliate-products'>
        <Swiper
        breakpoints={{
        320: {width:320, slidesPerView: 1,},
        425: {width:425, slidesPerView: 1},
        768: {width:768, slidesPerView:3}
      }}
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={5}
      slidesPerView={3}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
        >
          {
            retrievedProd.map((product,index)=>{
              return(
                <SwiperSlide>
                  <div className='app__afiliate-product' index={product.id}>
                    <img src={product.url} alt={product.Alt} />
                  </div>
                </SwiperSlide>
              )
            })
          }
        </Swiper>
        </div>   
        </div>
        <div className='app__afiliate-footer'>
            <Footer/>
        </div>
    </div>
  )
}

export default Afiliate