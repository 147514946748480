import { useState } from 'react'
import { MdKeyboardArrowRight } from 'react-icons/md'

import { Tours,Travel } from '../pages'

import './services.css'
import Destinations from './pages/Destinations'

const Services=()=> {
  const [view,setView] = useState("tours")
  // const [wordColor,setWordColor] = useState(false)

  const changeViewState=(e)=>{
    setView(e.target.className)
    // setWordColor(e.target.id)
  }
  
 
  return (
    <div id='services' className='services'>
      <h2 className='ours'>What we offer</h2>
      <div className='app__services-service'>
        <div className='app__services-service-nav'>
          <button onClick={changeViewState} className='tours' >Tours</button>
          <button onClick={changeViewState} className='travel'>Travel
          {/* <div className='travel-icon'><MdKeyboardArrowRight size={22}  /></div> */}
          </button>
          <p onClick={changeViewState} className='destinations'>Destinations</p>
        </div>
        <div className='app__services-service-display'>
          {
            view === "tours" ? (<Tours/>) : view ==="travel" ? (<Travel/>) : (<Destinations/>)
          }
        </div>
      </div>
    </div>
  )
}

export default Services