import { Navigation, Pagination, A11y,Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'
import 'swiper/css/pagination'

import { Service } from '../../../components/components'

import './ToursAndTravel.css'

const tours = [
  {
    "id": 1,
    "place":"Guides",
    "description":"Our expert guides ensure an unforgettable journey, unlocking hidden treasures and sharing their vast knowledge of nature and wildlife.",
    "cost": "Explore with the best!",
  },
  {
    "id": 2,
    "place":"Camping",
    "description":"Our top-notch camping setups guarantee comfort and safety amidst nature's beauty.",
    "cost": "Memorable Outdoor experiences",
  },
  {
    "id": 3,
    "place":"Accomodation",
    "description":"We secure the finest accommodations, from cozy lodges to luxurious resorts, ensuring your stay is as remarkable as your adventure.",
    "cost":"Rest easy with the planning",
  },
  {
    "id": 4,
    "place":"Car Hires",
    "description":"Our reliable car rental services offer the freedom to explore at your pace.",
    "cost":"Drive with ease.",
  },
  {
    "id": 5,
    "place":"Tour accessories",
    "description":"Explore our selection of high-quality tour accessories, handpicked to enhance your journey. Contact us and Shop for the best in travel essentials.",
    "cost":"Gear up.",
  },
  {
    "id": 6,
    "place":"Romantic Getaways",
    "description":"Let us craft a romantic getaway filled with love and enchantment.",
    "cost":"Ignite the spark",
  },
]

const Tours = () => {
  
  return (
    <div className='app__services_tours-page'>
      <Swiper
      breakpoints={{
        320: {width:320, slidesPerView: 1,},
        376: { width:376, slidesPerView: 1, }
        
      }}
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={5}
      slidesPerView={3}
      navigation
      pagination={{clickable: true}}
      autoplay={{ delay: 6000 }}
      scrollbar={{clickable: true}}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
      >
        {
          (tours.map(tour=>{
            return(
              <SwiperSlide key={tour.id} id={tour.id}>
                <div className='tour'>
                  <Service compName={tour.place} compDesc={tour.description} compCost={tour.cost} />
                </div>
              </SwiperSlide>
            )
          }))
        }
      </Swiper>
    </div>
  )
}

export default Tours