import { RxTwitterLogo,RxInstagramLogo } from 'react-icons/rx'
import { MdFacebook } from 'react-icons/md'
import { AiFillTikTok } from "react-icons/ai";
import { FaXTwitter,FaTiktok } from "react-icons/fa6";

import './footer.css'

const closure = [
  {
    "id":"1",
    "heading1":"About Office Hours",
    "itemA":"Open",
    "itemB":"Closes",
    "pA":"Mon - Fri (8am-5pm)",
    "pB":"Sat (8am-midday)",
  },
  {
    "id":"2",
    "heading2":"Socials",
    "item1":/*<RxTwitterLogo style={{marginLeft:1,marginRight:1,fontSize:25}} color='#132733' />*/<FaXTwitter className='fcons' style={{fontSize:21}} color='#25D366' />,
    "Lnk1":"https://twitter.com/talesofablack?t=TAUokmGJ6INWWITP_FXyMA&s=09",
    "item2":<MdFacebook className='fcons' style={{marginLeft:1,marginRight:1,fontSize:25}} color='#25D366'/>,
    "Lnk2":"https://www.facebook.com/profile.php?id=100087902167100&mibextid=ZbWKwL",
    "item3":<RxInstagramLogo className='fcons' style={{marginLeft:1,marginRight:1,fontSize:25}} color='#25D366'/>,
    "Lnk3":"https://instagram.com/blackmansafaris?igshid=ZDdkNTZiNTM=",
    "item4":<FaTiktok style={{fontSize:21,}} color='#25D366' />,
    "Lnk4":"https://www.tiktok.com/@blackman.safaris?_t=8oJXpomwPpO&_r=1",
  },
  // {
  //   "id":"3",
  //   "heading3":"Blog",
  //   "item9":"Explore stories",
  //   "Lnk":"",
  // },
  // {
  //   "id":"4",
  //   "symbol":<Symboll/>,
  //   "item7":"2023 Blackman safaris",
  //   "Lnk":"",
  // }
]

const Footer=()=> {
  return (
    <footer className='footer'>
      <div className='footerMain'>
        {
          closure.map((fut,index)=>{
            return(
            <>
              <div key={index.id} className='left'>
                <h1>{fut.heading1}</h1>
                <div className='futs'>
                  <div className='futsee'>
                    <h3>{fut.itemA}</h3>
                    <p>{fut.pA}</p>
                  </div>
                  <div className='futsee'>
                    <h3>{fut.itemB}</h3>
                    <p>{fut.pB}</p>
                  </div>
                </div>
              </div>
              <div key={index.id} className='mid'>
                <h1>{fut.heading2}</h1>
                <ul className='futsii'>
                  <li><a className='fcons' href={fut.Lnk1}>{fut.item1}</a></li>
                  <li><a className='fcons' href={fut.Lnk2}>{fut.item2}</a></li>
                  <li><a className='fcons' href={fut.Lnk3}>{fut.item3}</a></li>
                  <li><a className='fcons' href={fut.Lnk4}>{fut.item4}</a></li>
                </ul>
              </div>
              {/* <div key={index.id} className='right'>
                <h1>{fut.heading3}</h1>
                <ul className='futsoo'>
                  <li><a href={{}}>{fut.item9}</a></li>
                </ul>
              </div> */}
            </>
            )
          })
        }
      </div>
    <div className='footnote'>
      <small className='copyright'>&#169; 2024 Blackman Safaris</small>
      {/* <div className='floor'>
        <div className='floor1'></div>
        <div className='floor2'></div>
      </div> */}
    </div>
















      {/* <div className='app__footer-contain'>
        <div className='app__footer-left'>
          <h3 className='f-head'>Socials links</h3>
          <div className='f-body'>
            
            <a className='social-link twitter' href='https://twitter.com/talesofablack?t=TAUokmGJ6INWWITP_FXyMA&s=09' ><RxTwitterLogo size={30} color='#1DA1F2' />X</a>
            <a className='social-link facebook' href='https://www.facebook.com/profile.php?id=100087902167100&mibextid=ZbWKwL'><MdFacebook size={30} color='#1877F2' />Facebook</a>
            <a className='social-link instagram' href='https://instagram.com/blackmansafaris?igshid=ZDdkNTZiNTM='><RxInstagramLogo size={30} color='#E4405F' />Instagram</a>
          </div>
        </div>
        <div className='app__footer-right'>
          <h3 className='f-head'>Newsletter</h3>
          <div className='f-body'>
            <input ty />
          </div>
        </div>
      </div> 
      <div className='app__footer-bottom'>
        
        <small className='copyright'>&#169; 2023 Blackman safaris</small>
      </div>
          <ul key={index.id}>
            <h1>{fut.heading}</h1>
            <li></li>
          </ul> 
      */}
    </footer>
  )
}

export default Footer